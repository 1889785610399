<template>
  <div class="ft-filter-form">
    <b-row>
    <!--left-->
      <b-col>
        <!--hang 1 left-->
        <b-row no-gutters>
          <b-col cols="3">
            <b-form-group>
              <slot name="label">
                <feather-icon icon="ShoppingCartIcon" />Nguồn Shop
              </slot>
              <v-select
                v-model="selected"
                :options="source_options"
                @change="filterDone"
                label="text"
              />
            </b-form-group>
          </b-col>

          <b-col cols="3">
            <b-form-group
              label-class="labelNguonDonHang"
            >
              <slot name="label">
                <feather-icon icon="ArchiveIcon" />Nguồn Đơn Hàng
              </slot>
              <v-select
                v-model="form.order_options.selected"
                :options="constantOptions.orderSourceOptions"
                label="text"
                @change="filterDone"
              />
            </b-form-group>
          </b-col>

          <b-col cols="3">
            <b-form-group>
              <slot name="label">
                <feather-icon icon="TruckIcon" />Giao Hàng
              </slot>
              <v-select
                v-model="form.ship_options.selected"
                :options="form.ship_options.options"
                label="text"
                @change="filterDone"
              />
            </b-form-group>
          </b-col>

          <b-col cols="3">
            <b-form-group>
              <b-dropdown id="dropdown-form" text="Khu Vực Giao" ref="dropdown">
                <div class="tinh_thanh_dropdown">
                  <b-form-group>
                    <v-select
                      :options="getProvinces"
                      label="text"
                    />
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox>Nội Thành</b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox>Nội Bộ</b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox>Khuyến Mãi</b-form-checkbox>
                  </b-form-group>
                </div>
              </b-dropdown>
            </b-form-group>
          </b-col>

        </b-row>
        <!--hang 2 left-->

        <b-row no-gutters>
          <b-col cols="3">
            <b-form-group>
              <slot name="label">
                <feather-icon icon="GridIcon" />Mã Vạch
              </slot>
              <b-form-input
                v-model="form.codebar_options.selected"
                class="select_filter"
                @change="filterDone"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group>
              <slot name="label">
                <feather-icon icon="HashIcon" />Phân Loại
              </slot>
              <v-select
                v-model="form.category_options.selected"
                :options="constantOptions.CategoryOptions"
                label="text"
                @change="filterDone"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group>
              <slot name="label">
                <feather-icon icon="UsersIcon" />Nhóm Khách Hàng
              </slot>
              <v-select
                v-model="form.group_customer_options.selected"
                :options="constantOptions.groupCustomerOptions"
                label="text"
                @change="filterDone"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- hang 3 left-->
        <b-row no-gutters>
          <b-col cols="3">
            <b-form-group>
              <slot name="label">
                <feather-icon icon="MenuIcon" />In Đơn Hàng
              </slot>
              <v-select
                v-model="form.print_options.selected"
                :options="constantOptions.PrintOptions"
                label="text"
                @change="filterDone"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group>
              <slot name="label">
                <feather-icon icon="ShuffleIcon" />Đơn Trùng
              </slot>
              <v-select
                v-model="form.match_options.selected"
                :options="constantOptions.MatchOptions"
                label="text"
                @change="filterDone"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group>
              <slot name="label">
                <feather-icon icon="WifiIcon" />Nhà Mạng
              </slot>
              <v-select 
                multiple 
                v-model="form.telco_options.selected" 
                :options="constantOptions.TelcoOptions"
                label="text"
              >
                <template v-slot:option="option">
                  {{ option.text }}
                </template>
                <template v-slot:selected-option="option">
                  {{ option.text }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- hang 4 left-->
        <b-row>
          <b-col>
            <b-row no-gutters>
              <b-col cols="3">
                <b-form-group>
                  <slot name="label">
                    <feather-icon icon="MinusIcon" />Khoảng Giá Từ
                  </slot>
                  <cleave
                    id="number"
                    v-model="form.price_from"
                    class="form-control"
                    :raw="false"
                    placeholder="10,000"
                    :options="{
                      numeral: true,
                      numeralThousandsGroupStyle: 'thousand',
                    }"
                    @change="filterDone"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group>
                  <slot name="label">
                    <feather-icon icon="PlusIcon" />Đến
                  </slot>
                  <cleave
                    id="number"
                    v-model="form.price_to"
                    class="form-control"
                    :raw="false"
                    placeholder="10,000"
                    :options="{
                      numeral: true,
                      numeralThousandsGroupStyle: 'thousand',
                    }"
                    @change="filterDone"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <!--right-->
      <b-col>
        <!-- hang 1-->
        <b-row no-gutters>
          <b-col>
            <b-form-group>
              <slot name="label">
                <feather-icon icon="CopyIcon" />Loại Đơn
              </slot>
              <v-select
                v-model="form.type_order_options.selected"
                :options="constantOptions.typeOrderOptions"
                label="text"
                @change="filterDone"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <slot name="label">
                <feather-icon icon="MaximizeIcon" />Mã Hàng
              </slot>
              <b-form-input
                v-model="form.product_code_options.selected"
                class="select_filter"
                @change="filterDone"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <slot name="label">
                <feather-icon icon="UserIcon" />Nhân Viên
              </slot>
              <v-select
                v-model="form.staff_options.selected"
                :options="form.staff_options.options"
                label="text"
                @change="filterDone"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <slot name="label">
                <feather-icon icon="CommandIcon" />Marketing
              </slot>
              <v-select
                v-model="form.marketing_options.selected"
                :options="form.marketing_options.options"
                label="text"
                @change="filterDone"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-row no-gutters>
              <b-col>
                <b-form-group>
                  <slot name="label">
                    <feather-icon icon="UsersIcon" />NV Sales
                  </slot>
                  <v-select
                    v-model="form.sale_options.selected"
                    :options="form.sale_options.options"
                    label="text"
                    @change="filterDone"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <slot name="label">
                    <feather-icon icon="UsersIcon" />NV Chốt Đơn
                  </slot>
                  <v-select
                    v-model="form.staff_confirmed_options.selected"
                    :options="form.staff_confirmed_options.options"
                    label="text"
                    @change="filterDone"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <slot name="label">
                    <feather-icon icon="UploadCloudIcon" />Nguồn Upsale
                  </slot>
                  <v-select
                    v-model="form.upsale_options.selected"
                    :options="constantOptions.UpSaleOptions"
                    label="text"
                    @change="filterDone"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <slot name="label">
                    <feather-icon icon="HomeIcon" />Chọn Kho
                  </slot>
                  <v-select
                    v-model="form.upsale_options.selected"
                    :options="form.upsale_options.options"
                    @change="filterDone"
                    label="text"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!--hang 2-->
        <b-row>
          <b-col />
        </b-row>
        <!--hang 3-->
        <b-row no-gutters>
          <b-col cols="3">
            <b-form-group
              label="Tùy Chọn Hiển Thị"
            >
              <b-form-checkbox
                switch
                v-model="form.staff_allow_all"
                @change="filterDone">Hiển thị toàn bộ NV</b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="3" offset="3">
            <b-form-group>
              <slot name="label">
                <feather-icon icon="LinkIcon" />ID Facebook
              </slot>
              <b-form-input />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group>
              <slot name="label">
                <feather-icon icon="FacebookIcon" />Page Facebook
              </slot>
              <v-select :options="['Tất cả page']" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import PcVn from 'pc-vn'
import vSelect from 'vue-select'
import { BInputGroup, BInputGroupPrepend, BDropdown, BRow, BCol, BFormInput, BFormSelect, BFormGroup, BFormCheckbox } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import constants from '@/@core/app-config/constants'

export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      selected: { title: 'Square' },
      selected1: {
        title: 'Aperture',
        icon: 'ApertureIcon',
      },
      options: [{ title: 'Square' }, { title: 'Rectangle' }, { title: 'Rombo' }, { title: 'Romboid' }],
      //selected: { name: 'Square', id: 1 },
      //options: [{ name: 'Square', id: 1 }],
      form: {
        price_from: 0,
        price_to: 0,
        staff_allow_all: true,
        source_options: {
          selected: null,
          options: [{
            value: null, text: 'Nguồn shop', selected: true, disabled: true,
          }],
        },
        order_options: {
          selected: null,
        },
        ship_options: {
          selected: null,
        },
        province_options: {
          selected: null,
        },
        type_order_options: {
          selected: null,
        },
        product_code_options: {
          selected: '',
        },
        staff_options: {
          selected: null,
          options: [{
            value: null, text: 'Tất cả NV',
          }],
        },
        marketing_options: {
          selected: null,
          options: [{
            value: 1, text: 'Tất cả MKT',
          }],
        },
        sale_options: {
          selected: null,
          options: [{
            value: 1, text: 'Tất cả Sale',
          }],
        },
        staff_confirmed_options: {
          selected: null,
          options: [{
            value: 1, text: 'Tất cả NV',
          }],
        },
        upsale_options: {
          selected: null,
          options: [{
            value: null, text: 'Chọn Kho',
          },
          {
            value: 1, text: 'Kho Tổng',
          }],
        },
        codebar_options: {
          selected: null,
        },
        category_options: {
          selected: null,
        },
        group_customer_options: {
          selected: null,
        },
        print_options: {
          selected: null,
        },
        match_options: {
          selected: null,
        },
        telco_options: {
          selected: null,
          options: [
            { text: 'Tất cả nhà mạng' },
            { text: 'Viettel' },
            { text: 'Mobifone' },
            { text: 'Tất cả nhà mạng' },
          ],
        },
      },
    }
  },
  components: {
    vSelect,
    Cleave,
    BDropdown,
    BFormCheckbox,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
  },
  computed: {
    constantOptions() {
      return constants
    },
    getProvinces() {
      return PcVn.getProvinces().map(p => {
        return {
          text: p.name.replace('Tỉnh ', '').replace('Thành phố ', ''),
          value: p.code,
        }
      })
    },
  },
  methods: {
    filterDone() {
      this.$emit('filterDone', this.form)
    },
  },
}
</script>
<style lang="scss">

  .ft-filter-form {
    margin-top: 1.5rem;

    input, select, button, .vs__dropdown-toggle, textarea, .input-group-prepend .input-group-text, .form-control {
      border-radius: 0;
    }

    fieldset {
      margin-right: 0.5rem;
    }

    svg {
      margin-left: 0.5rem;
      margin-right: 0.2rem;
      margin-bottom: 0.1rem;
    }

    #dropdown-form {
      margin-top: 1.35rem;
    }
  }

  .select_filter {
    width: 100%
  }
  .labelNguonDonHang {
    font-size: 13px;
  }
  .tinh_thanh_dropdown {
    padding: 10px;
  }
</style>