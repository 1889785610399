<template>
  <div>
    <b-container
      fluid
    >
      <b-card-actions
        title="Bộ Lọc Tùy Chỉnh"
        no-body
        action-collapse
      >
        <b-card-header class="ft-filter-nav">
          <nav-filter @filterDone="navFilterFetch" />
        </b-card-header>
        <b-card-body>
          <date-filter @filterDone="dateFilterFetch"/>
          <status-filter @filterDone="statusFilterFetch"/>
          <form-filter @filterDone="formFilterFetch" />
        </b-card-body>
      </b-card-actions>

      <!-- Table Container Card -->
      <b-card
        no-body
      >
        <b-card-header>
          <b-card-sub-title class="ft-card-sub-table">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="success"
              :to="{ name: 'orders-create'}"
            >
              <feather-icon icon="PlusIcon" /> Tạo Đơn mới
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="info"
            >
              <feather-icon icon="CheckIcon" /> Thao tác đơn hàng được chọn
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
            >
              <feather-icon icon="CheckSquareIcon" /> Thao tác đơn hàng hiển thị
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="info"
            >
              <feather-icon icon="DownloadIcon" /> Excel
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="warning"
            >
              <feather-icon icon="DownloadIcon" /> Gửi Email cho NVC
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="success"
            >
              <feather-icon icon="FacebookIcon" /> Đồng Bộ
            </b-button>
          </b-card-sub-title>
          <b-form-select style="width: 100px" v-model="pageLength" :options="[{value: 5, text: 5},{value: 10, text: 10},{value: 15, text: 15}]" />
        </b-card-header>
        <b-card-body class="pt-0">
          <b-tabs>
            <template #tabs-end>
              <li role="presentation" class="nav-item align-self-center ft-tab-link"><b-link><feather-icon icon="EditIcon" />Chỉnh Sửa Nhanh</b-link></li>
              <li role="presentation" class="nav-item align-self-center ft-tab-link"><b-link><feather-icon icon="FileIcon" />Landing Page</b-link></li>
              <li role="presentation" class="nav-item align-self-center ft-tab-link"><b-link><feather-icon icon="LogInIcon" />Import Excel</b-link></li>
              <li role="presentation" class="nav-item align-self-center ft-tab-link ft-background-tab-link"><b-link><feather-icon icon="ArrowUpRightIcon" /> Xuất Excel vận chuyển</b-link></li>
              <li role="presentation" class="nav-item align-self-center ft-margin-left-auto">Không chọn điều kiện lọc sẽ hiển thị DS đơn 6 tháng gần nhất!</li>
            </template>
            <b-tab active title="Danh sách Đơn hàng">
              <b-table
                striped
                sticky-header
                bordered
                ref="selectableTable"
                selectable
                :select-mode="selectMode"
                :items="items"
                :fields="fields"
                responsive
                class="mb-0"
                @row-selected="onRowSelected"
                :busy="isBusy"
              >
                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <span aria-hidden="true">&check;</span>
                    <span class="sr-only">Selected</span>
                  </template>
                  <template v-else>
                    <span aria-hidden="true">&nbsp;</span>
                    <span class="sr-only">Not selected</span>
                  </template>
                </template>
                <template #table-busy>
                  <div class="text-danger my-2">
                    <b-spinner class="align-middle" />
                    <strong> Loading...</strong>
                  </div>
                </template>
                <template #cell(avatar)="data">
                  <b-avatar :src="data.value" />
                </template>

                <template #cell(status)="data">
                  <b-badge :variant="status[1][data.value]">
                    {{ status[0][data.value] }}
                  </b-badge>
                </template>
              </b-table>
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { 
  BTabs,
  BTab,
  BTable, 
  BRow,
  BCol,
  BFormInput,
  BMedia,
  BLink,
  BDropdown,
  BDropdownItem,
  BTooltip,
  BButton, 
  BFormGroup, 
  BAvatar, 
  BCardBody, 
  BCardHeader, 
  BContainer, 
  BPagination, 
  BFormSelect, 
  BCard, 
  BBadge,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'flatpickr'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'

import NavTable from '@core/components/datatable/NavTable.vue'
import ColStatus from '@core/components/datatable/ColStatus.vue'
import ColAction from '@core/components/datatable/ColAction.vue'
import DateFilter from './components/filter/date.vue'
import FormFilter from './components/filter/form.vue'
import NavFilter from './components/filter/nav.vue'

import StatusFilter from './components/filter/status.vue'
import SortColumnTable from './components/modal/sortColumnTable.vue'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/themes/material_blue.css'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'

import useInvoicesList from './useInvoiceList'

import invoiceStoreModule from './invoiceStoreModule'

export default {
  watch: {
  },
  components: {
    BTabs,
    BTab,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BTooltip,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    vSelect,
    BCardBody,
    BCardHeader,
    SortColumnTable,
    NavFilter,
    FormFilter,
    StatusFilter,
    DateFilter,
    ColAction,
    ColStatus,
    NavTable,
    flatPickr,
    BPagination,
    BFormSelect,
    BContainer,
    BCard,
    BBadge,
    BCardActions
},
  directives: {
    Ripple,
  },
  mounted() {
    flatPickr('input[placeholder="Search"]', {
      dateFormat: 'd-m-Y',
      allowInput: true,
    })
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      fields: [
        {
          key: 'selected',
          label: 'Chọn',
        },
        {
          label: 'STT',
          key: 'stt',
          thStyle: { 'min-width': '300px!important'},
          enabled: true,
        },
        {
          label: 'Địa chỉ',
          key: 'dia_chi',
          sortable: true,
          enabled: true,
          thStyle: { 'min-width': '300px!important'}
        },
        {
          label: 'NV được chia',
          key: 'nv_duoc_chia',
          sortable: true,
          width: '300px',
          enabled: true,
          thStyle: { 'min-width': '300px!important'}
        },
        {
          label: 'Mã VĐ',
          sortable: true,
          key: 'ma_vd',
          width: '200px',
          enabled: true,
          thStyle: { 'min-width': '300px!important'}
        },
        {
          label: 'Số DT',
          sortable: true,
          width: '200px',
          key: 'so_dt',
          enabled: true,
          thStyle: { 'min-width': '300px!important'}
        },
        {
          label: 'Nguồn',
          sortable: true,
          thStyle: { 'min-width': '300px!important'},
          key: 'nguon',
          enabled: true,
        },
        {
          label: 'Sản phẩm',
          sortable: true,
          thStyle: { 'min-width': '300px!important'},
          key: 'san_pham',
          enabled: true,
        },
        {
          label: 'Tên KH',
          sortable: true,
          thStyle: { 'min-width': '300px!important'},
          key: 'ten_kh',
          enabled: true,
        },
        {
          label: 'FB KH',
          sortable: true,
          key: 'fb_kh',
          thStyle: { 'min-width': '300px!important'},
          enabled: true,
        },
        {
          label: 'Tỉnh/Thành',
          sortable: true,
          key: 'tinh_thanh',
          thStyle: { 'min-width': '300px!important'},
          enabled: true,
        },
        {
          label: 'Mã',
          sortable: true,
          key: 'ma',
          thStyle: { 'min-width': '300px!important'},
          enabled: true,
        },
        {
          label: 'Bài Post',
          sortable: true,
          key: 'bai_post',
          thStyle: { 'min-width': '300px!important'},
          enabled: true,
        },
        {
          label: 'Page',
          sortable: true,
          key: 'page',
          thStyle: { 'min-width': '300px!important'},
          enabled: true,
        },
        {
          label: 'Note Chung',
          sortable: true,
          key: 'note_chung',
          enabled: true,
          thStyle: { 'min-width': '300px!important'},
        },
        {
          label: 'Note 2',
          sortable: true,
          key: 'note_2',
          thStyle: { 'min-width': '300px!important'},
          enabled: true,
        },
        {
          label: 'Ngày Tạo',
          sortable: true,
          key: 'ngay_tao',
          enabled: true,
          thStyle: { 'min-width': '300px!important'},
        },
        {
          label: 'Trạng thái',
          sortable: true,
          key: 'trang_thai',
          thStyle: { 'min-width': '300px!important'},
          enabled: true,
        },
        {
          label: 'Tổng tiền',
          thStyle: { 'min-width': '300px!important'},
          sortable: true,
          key: 'tong_tien',
          enabled: true,
        },
        {
          label: 'Trùng đơn',
          sortable: true,
          thStyle: { 'min-width': '300px!important'},
          key: 'trung_don',
          enabled: true,
        },
        {
          label: 'NV Xác Nhận',
          sortable: true,
          thStyle: { 'min-width': '300px!important'},
          key: 'nv_xac_nhan',
          enabled: true,
        },
        {
          label: 'Ngày XN',
          thStyle: { 'min-width': '300px!important'},
          sortable: true,
          key: 'ngay_xn',
          enabled: true,
        },
        {
          label: 'Ngày Chuyển',
          sortable: true,
          thStyle: { 'min-width': '300px!important'},
          key: 'ngay_chuyen',
          enabled: true,
        },
        {
          label: 'Người Chuyển',
          sortable: true,
          thStyle: { 'min-width': '300px!important'},
          key: 'nguoi_chuyen',
          enabled: true,
        },
        {
          label: 'Người Tạo',
          sortable: true,
          thStyle: { 'min-width': '300px!important'},
          key: 'nguoi_tao',
          enabled: true,
        },
        {
          label: 'Mã Bưu điện',
          sortable: true,
          key: 'ma_buu_dien',
          thStyle: { 'min-width': '300px!important'},
          enabled: true,
        },
        {
          label: 'Note Giao hàng',
          thStyle: { 'min-width': '300px!important'},
          sortable: true,
          key: 'note_giao_hang',
          enabled: true,
        },
        {
          label: 'Công ty',
          thStyle: { 'min-width': '300px!important'},
          sortable: true,
          key: 'cong_ty',
          enabled: true,
        },
        {
          label: 'Nguồn Up Sale',
          sortable: true,
          thStyle: { 'min-width': '300px!important'},
          key: 'nguon_up_sale',
          enabled: true,
        },
        {
          label: 'Email KH',
          sortable: true,
          thStyle: { 'min-width': '300px!important'},
          key: 'email_kh',
          enabled: true,
        },
        {
          label: 'Thành Tiền',
          sortable: true,
          key: 'thanh_tien',
          thStyle: { 'min-width': '300px!important'},
          enabled: true,
        },
        {
          label: 'NV đã trả hàng về Kho',
          sortable: true,
          key: 'nv_da_tra_hang_ve_kho',
          enabled: true,
          thStyle: { 'min-width': '300px!important'},
        },
        {
          label: 'Ngày đã trả hàng về Kho',
          sortable: true,
          key: 'ngay_da_tra_hang_ve_kho',
          thStyle: { 'min-width': '300px!important'},
          enabled: true,
        },
        {
          label: 'NV kế toán mặc định',
          sortable: true,
          key: 'nv_ke_toan_mac_dinh',
          thStyle: { 'min-width': '300px!important'},
          enabled: true,
        },
        {
          label: 'Ngày kế toán mặc định',
          sortable: true,
          key: 'ngay_ke_toan_mac_dinh',
          enabled: true,
          thStyle: { 'min-width': '300px!important'},
        },
        {
          label: 'Ngày chuyển hoàn',
          sortable: true,
          thStyle: { 'min-width': '300px!important'},
          key: 'ngay_chuyen_hoan',
          enabled: true,
        },
        {
          label: 'NV đã thu tiền',
          sortable: true,
          key: 'nv_da_thu_tien',
          thStyle: { 'min-width': '300px!important'},
          enabled: true,
        },
        {
          label: 'Ngày đã thu tiền',
          sortable: true,
          thStyle: { 'min-width': '300px!important'},
          key: 'ngay_da_thu_tien',
          enabled: true,
        },
        {
          label: 'NV thành công',
          sortable: true,
          key: 'nv_thanh_cong',
          thStyle: { 'min-width': '300px!important'},
          enabled: true,
        },
        {
          label: 'Ngày thành công',
          sortable: true,
          thStyle: { 'min-width': '300px!important'},
          key: 'ngay_thanh_cong',
          enabled: true,
        },
        {
          label: 'Phí VC',
          sortable: true,
          key: 'phi_vc',
          thStyle: { 'min-width': '300px!important'},
          enabled: true,
        },
        {
          label: 'Phụ Thu',
          sortable: true,
          key: 'phu_thu',
          thStyle: { 'min-width': '300px!important'},
          enabled: true,
        },
        {
          label: 'Mã KH',
          sortable: true,
          key: 'ma_kh',
          enabled: true,
          thStyle: { 'min-width': '300px!important'},
        },
        {
          label: 'Nhóm KH',
          sortable: true,
          key: 'nhom_kh',
          enabled: true,
          thStyle: { 'min-width': '300px!important'},
        },
        {
          label: 'Lý Do Hủy',
          sortable: true,
          key: 'ly_do_huy',
          thStyle: { 'min-width': '300px!important'},
          enabled: true,
        },
        {
          label: 'SĐT phụ',
          thStyle: { 'min-width': '300px!important'},
          sortable: true,
          key: 'sdt_phu',
          enabled: true,
        },
        {
          label: 'Trả trước',
          sortable: true,
          thStyle: { 'min-width': '300px!important'},
          key: 'tra_truoc',
          enabled: true,
        },
        {
          label: 'Còn Nợ',
          sortable: true,
          key: 'con_no',
          thStyle: { 'min-width': '300px!important'},
          enabled: true,
        },
        {
          label: 'SĐT Chính/Phụ',
          thStyle: { 'min-width': '300px!important'},
          sortable: true,
          key: 'sdt_chinh_phu',
          enabled: true,
        },
        {
          label: 'Giao hàng',
          sortable: true,
          thStyle: { 'min-width': '300px!important'},
          key: 'giao_hang',
          enabled: true,
        },
        {
          label: 'Phân Loại sản phẩm',
          thStyle: { 'min-width': '300px!important'},
          sortable: true,
          key: 'phan_loai_san_pham',
          enabled: true,
        },
        {
          label: 'Loại đơn',
          sortable: true,
          thStyle: { 'min-width': '300px!important'},
          key: 'loai_don',
          enabled: true,
        },
        {
          label: 'Nguồn Shop',
          sortable: true,
          key: 'nguon_shop',
          thStyle: { 'min-width': '300px!important'},
          enabled: true,
        },
        {
          label: 'Nguồn Người Tạo',
          sortable: true,
          key: 'nguon_nguoi_tao',
          thStyle: { 'min-width': '300px!important'},
          enabled: true,
        },
        {
          label: 'Nguồn Người UpSale',
          sortable: true,
          key: 'nguon_nguoi_upsale',
          thStyle: { 'min-width': '300px!important'},
          enabled: true,
        },
        {
          label: 'Mã Sản phẩm',
          sortable: true,
          key: 'ma_san_pham',
          enabled: true,
          thStyle: { 'min-width': '300px!important'},
        },
        {
          label: 'Nhà Mạng',
          sortable: true,
          key: 'nha_mang',
          thStyle: { 'min-width': '300px!important'},
          enabled: true,
        },
      ],
      searchTermOrder: '',
      selectedProductItems: [],
      exports_row: [{
        name: 'PRINT',
        fn: this.exportPrint,
      },
      {
        name: 'CSV',
        fn: this.exportPrint,
      },
      {
        name: 'EXCEL',
        fn: this.exportPrint,
      },
      {
        name: 'PDF',
        fn: this.exportPrint,
      }],


      // selectedCheck: false,
      modes: ['multi', 'single', 'range'],
      items: [
      ],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      selectMode: 'multi',
      selected: [],
      isBusy: false,
    }
  },
  computed: {
    columnFilterEnabled() {
      return this.orders_columns.filter(item => item.enabled)
    },
  },
  created() {
    this.isBusy = false
    this.items = [
      {
        selected: true,
        act: 'ss',
        stt: '1',
        dia_chi: 'Kiên Giang',
        nv_duoc_chia: 'Danh Sinh',
        ma_vd: '1234',
        so_dt: '096 999 9999',
        nguon: 'FaceBook',
        san_pham: 'Sản Phâm 1',
        ten_kh: 'Danh Sinh',
        fb_kh: 'Danh Sinh',
        tinh_thanh: 'HCM',
        ma: '920000',
        bai_post: '',
        page: '',
        note_chung: '',
        note_2: '',
        ngay_tao: '',
        trang_thai: '',
        tong_tien: '',
        trung_don: '',
        nv_xac_nhan: '',
        ngay_xn: '',
        ngay_chuyen: '',
        nguoi_chuyen: '',
        nguoi_tao: '',
        ma_buu_dien: '',
        note_giao_hang: '',
        cong_ty: '',
        nguon_up_sale: '',
        email_kh: '',
        thanh_tien: '',
        nv_da_tra_hang_ve_kho: '',
        ngay_da_tra_hang_ve_kho: '',
        nv_ke_toan_mac_dinh: '',
        ngay_ke_toan_mac_dinh: '',
        ngay_chuyen_hoan: '',
        nv_da_thu_tien: '',
        ngay_da_thu_tien:'',
        nv_thanh_cong: '',
        ngay_thanh_cong: '',
        phi_vc: '',
        phu_thu: '',
        ma_kh: '',
        nhom_kh: '',
        ly_do_huy: '',
        sdt_phu: '',
        tra_truoc: '',
        con_no: '',
        sdt_chinh_phu: '',
        giao_hang: '',
        phan_loai_san_pham: '',
        loai_don: '',
        nguon_shop: '',
        nguon_nguoi_tao: 'FB Comment',
        nguon_nguoi_upsale: 'Danh Sinh',
        ma_san_pham: '12223',
        nha_mang: 'Viettel, MobiFone',
      },
      {
        selected: true,
        act: 'ss',
        stt: '1',
        dia_chi: 'Hà Nội',
        nv_duoc_chia: 'Danh Sinh',
        ma_vd: '1234',
        so_dt: '096 999 9999',
        nguon: 'FaceBook',
        san_pham: 'Sản Phâm 1',
        ten_kh: 'Danh Sinh',
        fb_kh: 'Danh Sinh',
        tinh_thanh: 'HCM',
        ma: '920000',
        bai_post: '',
        page: '',
        note_chung: '',
        note_2: '',
        ngay_tao: '',
        trang_thai: '',
        tong_tien: '',
        trung_don: '',
        nv_xac_nhan: '',
        ngay_xn: '',
        ngay_chuyen: '',
        nguoi_chuyen: '',
        nguoi_tao: '',
        ma_buu_dien: '',
        note_giao_hang: '',
        cong_ty: '',
        nguon_up_sale: '',
        email_kh: '',
        thanh_tien: '',
        nv_da_tra_hang_ve_kho: '',
        ngay_da_tra_hang_ve_kho: '',
        nv_ke_toan_mac_dinh: '',
        ngay_ke_toan_mac_dinh: '',
        ngay_chuyen_hoan: '',
        nv_da_thu_tien: '',
        ngay_da_thu_tien:'',
        nv_thanh_cong: '',
        ngay_thanh_cong: '',
        phi_vc: '',
        phu_thu: '',
        ma_kh: '',
        nhom_kh: '',
        ly_do_huy: '',
        sdt_phu: '',
        tra_truoc: '',
        con_no: '',
        sdt_chinh_phu: '',
        giao_hang: '',
        phan_loai_san_pham: '',
        loai_don: '',
        nguon_shop: '',
        nguon_nguoi_tao: 'FB Comment',
        nguon_nguoi_upsale: 'Danh Sinh',
        ma_san_pham: '12223',
        nha_mang: 'Viettel, MobiFone',
      },
      {
        selected: true,
        act: 'ss',
        stt: '1',
        dia_chi: 'Hà Nội',
        nv_duoc_chia: 'Danh Sinh',
        ma_vd: '1234',
        so_dt: '096 999 9999',
        nguon: 'FaceBook',
        san_pham: 'Sản Phâm 1',
        ten_kh: 'Danh Sinh',
        fb_kh: 'Danh Sinh',
        tinh_thanh: 'HCM',
        ma: '920000',
        bai_post: '',
        page: '',
        note_chung: '',
        note_2: '',
        ngay_tao: '',
        trang_thai: '',
        tong_tien: '',
        trung_don: '',
        nv_xac_nhan: '',
        ngay_xn: '',
        ngay_chuyen: '',
        nguoi_chuyen: '',
        nguoi_tao: '',
        ma_buu_dien: '',
        note_giao_hang: '',
        cong_ty: '',
        nguon_up_sale: '',
        email_kh: '',
        thanh_tien: '',
        nv_da_tra_hang_ve_kho: '',
        ngay_da_tra_hang_ve_kho: '',
        nv_ke_toan_mac_dinh: '',
        ngay_ke_toan_mac_dinh: '',
        ngay_chuyen_hoan: '',
        nv_da_thu_tien: '',
        ngay_da_thu_tien:'',
        nv_thanh_cong: '',
        ngay_thanh_cong: '',
        phi_vc: '',
        phu_thu: '',
        ma_kh: '',
        nhom_kh: '',
        ly_do_huy: '',
        sdt_phu: '',
        tra_truoc: '',
        con_no: '',
        sdt_chinh_phu: '',
        giao_hang: '',
        phan_loai_san_pham: '',
        loai_don: '',
        nguon_shop: '',
        nguon_nguoi_tao: 'FB Comment',
        nguon_nguoi_upsale: 'Danh Sinh',
        ma_san_pham: '12223',
        nha_mang: 'Viettel, MobiFone',
      },
      {
        selected: true,
        act: 'ss',
        stt: '1',
        dia_chi: 'Hà Nội',
        nv_duoc_chia: 'Danh Sinh',
        ma_vd: '1234',
        so_dt: '096 999 9999',
        nguon: 'FaceBook',
        san_pham: 'Sản Phâm 1',
        ten_kh: 'Danh Sinh',
        fb_kh: 'Danh Sinh',
        tinh_thanh: 'HCM',
        ma: '920000',
        bai_post: '',
        page: '',
        note_chung: '',
        note_2: '',
        ngay_tao: '',
        trang_thai: '',
        tong_tien: '',
        trung_don: '',
        nv_xac_nhan: '',
        ngay_xn: '',
        ngay_chuyen: '',
        nguoi_chuyen: '',
        nguoi_tao: '',
        ma_buu_dien: '',
        note_giao_hang: '',
        cong_ty: '',
        nguon_up_sale: '',
        email_kh: '',
        thanh_tien: '',
        nv_da_tra_hang_ve_kho: '',
        ngay_da_tra_hang_ve_kho: '',
        nv_ke_toan_mac_dinh: '',
        ngay_ke_toan_mac_dinh: '',
        ngay_chuyen_hoan: '',
        nv_da_thu_tien: '',
        ngay_da_thu_tien:'',
        nv_thanh_cong: '',
        ngay_thanh_cong: '',
        phi_vc: '',
        phu_thu: '',
        ma_kh: '',
        nhom_kh: '',
        ly_do_huy: '',
        sdt_phu: '',
        tra_truoc: '',
        con_no: '',
        sdt_chinh_phu: '',
        giao_hang: '',
        phan_loai_san_pham: '',
        loai_don: '',
        nguon_shop: '',
        nguon_nguoi_tao: 'FB Comment',
        nguon_nguoi_upsale: 'Danh Sinh',
        ma_san_pham: '12223',
        nha_mang: 'Viettel, MobiFone',
      },
      {
        selected: true,
        act: 'ss',
        stt: '1',
        dia_chi: 'Hà Nội',
        nv_duoc_chia: 'Danh Sinh',
        ma_vd: '1234',
        so_dt: '096 999 9999',
        nguon: 'FaceBook',
        san_pham: 'Sản Phâm 1',
        ten_kh: 'Danh Sinh',
        fb_kh: 'Danh Sinh',
        tinh_thanh: 'HCM',
        ma: '920000',
        bai_post: '',
        page: '',
        note_chung: '',
        note_2: '',
        ngay_tao: '',
        trang_thai: '',
        tong_tien: '',
        trung_don: '',
        nv_xac_nhan: '',
        ngay_xn: '',
        ngay_chuyen: '',
        nguoi_chuyen: '',
        nguoi_tao: '',
        ma_buu_dien: '',
        note_giao_hang: '',
        cong_ty: '',
        nguon_up_sale: '',
        email_kh: '',
        thanh_tien: '',
        nv_da_tra_hang_ve_kho: '',
        ngay_da_tra_hang_ve_kho: '',
        nv_ke_toan_mac_dinh: '',
        ngay_ke_toan_mac_dinh: '',
        ngay_chuyen_hoan: '',
        nv_da_thu_tien: '',
        ngay_da_thu_tien:'',
        nv_thanh_cong: '',
        ngay_thanh_cong: '',
        phi_vc: '',
        phu_thu: '',
        ma_kh: '',
        nhom_kh: '',
        ly_do_huy: '',
        sdt_phu: '',
        tra_truoc: '',
        con_no: '',
        sdt_chinh_phu: '',
        giao_hang: '',
        phan_loai_san_pham: '',
        loai_don: '',
        nguon_shop: '',
        nguon_nguoi_tao: 'FB Comment',
        nguon_nguoi_upsale: 'Danh Sinh',
        ma_san_pham: '12223',
        nha_mang: 'Viettel, MobiFone',
      },
    ]
  },
  methods: {
    navFilterFetch(form) {
      console.log('navFilterFetch', form)
      this.filterFetch()
    },
    statusFilterFetch(form) {
      console.log('statusFilterFetch', form)
      this.filterFetch()
    },
    formFilterFetch(form) {
      console.log('formFilterFetch', form)
      this.filterFetch()
    },
    dateFilterFetch(form) {
      console.log('dateFilterFetch', form)
      this.filterFetch()
    },
    dateRangeFilter(data, filterString) {
      const dateRange = filterString.split('to')
      const startDate = Date.parse(dateRange[0])
      const endDate = Date.parse(dateRange[1])
      const dataOut = Date.parse(data) >= startDate && Date.parse(data) <= endDate
      return dataOut
    },
    exportPrint(t) {
      console.log('type', t)
    },
    showMsgBoxConfirmDelete(id) {
      // delete row
      this.$bvModal
        .msgBoxConfirm(`Có phải bạn muốn xóa dòng ${id} không?`, {
          title: 'Xác nhận',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            // xóa dòng
            this.rows = this.rows.filter(r => r.id !== id)
          }
        })
    },
    selectionChanged(rows) {
      // neu thong tin thừa lấy cái đầu tiên
      this.selectedProductItems = rows.selectedRows.map(row => row.id)
    },
    confirmDeleteSelected(rows) {
      this.$bvModal
        .msgBoxConfirm(`Có phải bạn muốn xóa dòng ${rows.length} không?`, {
          title: 'Xác nhận',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            // xóa c dòng
            this.rows = this.rows.filter(item => !rows.includes(item.id))
          }
        })
    },
    filterFetch() {
      this.isBusy = true
      setTimeout(() => {
        this.isBusy = false
      }, 5000)
      this.rows = []
    },
    sortColumn() {
      // sort column
    },

    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      'Downloaded',
      'Draft',
      'Paid',
      'Partial Payment',
      'Past Due',
    ]

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useInvoicesList()

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      statusOptions,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style>
  .ft-order-card .card-header {
    padding: 0.3;
    background-color: #d2d6de!important;
    margin-bottom: 1rem;
  }
  .ft-background-tab-link {
    background-color: #1ab61a;
    padding: 2px!important;
    border-radius: 5px;
  }
  .ft-background-tab-link a {
    color: #fff;
  }
  .ft-tab-link {
    padding: 11px;
  }
  .ft-margin-left-auto {
    margin-left: auto;
  }
  .ft-card-sub-table button {
    margin-left: 5px;
  }
</style>
<style lang="scss" >
  .b-table-selectable{
    .feather{
      font-size: 1.3rem;
    }
  }

  .ft-filter-nav {
    padding: 0.5rem!important;
    background-color: #7367f0!important;
    margin-bottom: 1rem;
    border-radius: 0!important;

    input, button {
      border-radius: 0;
    }
    input::placeholder {
      color: #3b4253;
    }

    .col {
      padding: 0 0.3rem!important;
    }
  }
</style>
