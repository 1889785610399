<template>
  <div class="order-status-card ft-filter-status">
    <b-row class="status-bar">
      <b-col>
        <span>Trạng Thái Đơn Hàng: </span>
        <b-button-group
          size="sm"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="StatusSelect('tat_ca')"
          >
            Chọn Tất Cả
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="StatusSelect('reset')"
          >
            Bỏ Chọn
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="StatusSelect('doanh_thu')"
          >
            Tính Doanh Thu
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="StatusSelect('khong_tinh_doanh_thu')"
          >
            Không Tính Doanh Thu
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <b-row class="status-content">
      <b-col
        v-for="st in status"
        :key="st.id"
        cols="2"
        class="status-content-item"
        :style="{borderLeft: '10px solid ' + st.color }"
      >
        <b-form-checkbox
          v-model="st.tiep_can"
          @change="filterStatus"
          plain
        >
          <span :style="{color: st.color}">{{ st.name }}</span>
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { BButtonGroup, BButton, BRow, BCol, BFormCheckbox } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  methods: {
    filterStatus() {
      this.$emit('filterDone', this.status)
    },
    StatusSelect(t) {
      this.status = this.status.map((item, k) => {
        if (t === 'tat_ca') {
          return { ...item, tiep_can: true }
        } else if ( t === 'reset' ) {
          return { ...item, tiep_can: false }
        } else if (t === 'khong_tinh_doanh_thu'){
          if([1, 2, 3, 4, 5, 6].includes(item.id))
            return { ...item, tiep_can: true }
          else return { ...item, tiep_can: false }
        }
        else if(t === 'doanh_thu') {
          if([7, 8, 9, 10, 11, 12].includes(item.id))
            return { ...item, tiep_can: true }
          else return { ...item, tiep_can: false }
        }else {
          return item;
        }
      })
    }
  },
  data() {
    return {
      status: [
        {
          name: 'Chưa Xác nhận',
          id: 1,
          tiep_can: true,
          tinh_doanh_thu: false,
          location: 1,
          color: '#76ec39',
          level: 1,
        },
        {
          name: 'Khai thác lại',
          id: 2,
          tiep_can: false,
          tinh_doanh_thu: false,
          location: 1,
          color: '',
          level: 1,
        },
        {
          name: 'Không nghe điện',
          id: 3,
          tiep_can: false,
          tinh_doanh_thu: false,
          location: 1,
          color: '#337ab7',
          level: 1,
        },
        {
          name: 'Hủy',
          id: 4,
          tiep_can: false,
          tinh_doanh_thu: false,
          location: 1,
          color: '#7a7980',
          level: 1,
        },
        {
          name: 'Gọi Máy Bận',
          id: 5,
          tiep_can: false,
          tinh_doanh_thu: false,
          location: 1,
          color: '#dabc51',
          level: 1,
        },
        {
          name: 'Kế Toán mặc định',
          id: 6,
          tiep_can: false,
          tinh_doanh_thu: false,
          location: 1,
          color: '#ff0099',
          level: 1,
        },
        {
          name: 'Xác nhận chốt đơn',
          id: 7,
          tiep_can: false,
          tinh_doanh_thu: false,
          location: 1,
          color: '#cc6633',
          level: 1,
        },
        {
          name: 'Chuyển Hàng',
          id: 8,
          tiep_can: false,
          tinh_doanh_thu: false,
          location: 1,
          color: '#FF0000',
          level: 1,
        },
        {
          name: 'Chuyển Hoàn',
          id: 9,
          tiep_can: false,
          tinh_doanh_thu: false,
          location: 1,
          color: '#000000',
          level: 1,
        },
        {
          name: 'Thành công',
          id: 10,
          tiep_can: false,
          tinh_doanh_thu: false,
          location: 1,
          color: '#996699',
          level: 1,
        },
        {
          name: 'Đã thu Tiền',
          id: 11,
          tiep_can: false,
          tinh_doanh_thu: false,
          location: 1,
          color: '#00a65a',
          level: 1,
        },
        {
          name: 'Trả hàng về Kho',
          id: 12,
          tiep_can: false,
          tinh_doanh_thu: false,
          location: 1,
          color: '#331211',
          level: 1,
        },
      ],
    }
  },
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BButtonGroup,
    BButton,
  },
  directives: {
    Ripple,
  },
}
</script>
<style>
  .order-status-card {
    padding: 0 0.5rem;
  }
  .status-bar {
    padding-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
  }
  .status-content {
    background-color: rgb(245, 245, 241);
    padding: 0.5rem 1.5rem;
  }
  .status-content span {
    font-size: 1rem;
    padding: 0.3rem 0.5rem;
  }
  .status-content-item:hover {
    background-color: rgb(240, 239, 179);
  }
  .status-content-item {
    padding: 0.3rem 0.5rem;
    border: 1px solid rgb(189, 189, 189);
    margin: 0.3rem 0;
  }
  .status-content-item label {
    width: 100%;
  }
  .status-bar .col span {
    margin-right: 0.5rem;
  }
</style>
