<template>
  <b-container fluid class="custom-search">
    <!-- advance search input -->
    <b-row>
      <b-col cols="5">
        <b-row>
          <b-col>
            <b-form-group class="nav-form-group">
              <b-form-input size="sm" placeholder="SĐT (tối thiểu 4 số)" v-model="form.phone_filter" @change="formFilter" style="border-radius: 0" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group class="nav-form-group">
              <b-form-input size="sm" placeholder="Mã Đơn Hàng" v-model="form.order_code_filter" @change="formFilter"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group class="nav-form-group">
              <b-form-input size="sm" placeholder="Mã Vận Đơn" v-model="form.order_code_filter" @change="formFilter"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group class="nav-form-group">
              <b-form-input size="sm" placeholder="Họ Tên Khách Hàng" v-model="form.fullname_filter" @change="formFilter"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group class="nav-form-group">
              <b-form-input size="sm" placeholder="Mã Khách Hàng" v-model="form.fullname_code_filter" @change="formFilter"/></b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col offset="4" style="text-align: right;">
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="info"
          size="sm"
        >
          <feather-icon
            icon="ListIcon"
            class="mr-50"
          />
          <span class="align-middle">Chia Đơn Nhanh</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="success"
          size="sm"
          v-b-modal.modal-1
          style="margin-left: 15px"
        >
          <feather-icon
            icon="SettingsIcon"
            class="mr-50"
          />
          <span class="align-middle">Sắp Xếp Cột</span>
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import { BFormGroup, BFormInput, BRow, BCol, BButton, BContainer } from 'bootstrap-vue'

export default {
  components: {
    BFormGroup, BFormInput, BRow, BCol, BButton, BContainer,
  },
  data() {
    return {
      form: {
        phone_filter: '',
        order_code_filter: '',
        fullname_filter: '',
        fullname_code_filter: '',
        ship_code_filter: '',
      },
    }
  },
  methods: {
    formFilter() {
      this.$emit('filterDone', this.form)
    },
  },

}
</script>
<style>
.nav-form-group {
  margin-bottom: 0px;
}
</style>
